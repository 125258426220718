<template>
  <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.875 10.375H1.70833C1.42102 10.375 1.14547 10.2609 0.942301 10.0577C0.739137 9.85453 0.625 9.57898 0.625 9.29167V1.70833C0.625 1.42102 0.739137 1.14547 0.942301 0.942301C1.14547 0.739137 1.42102 0.625 1.70833 0.625H3.875M7.66667 8.20833L10.375 5.5M10.375 5.5L7.66667 2.79167M10.375 5.5H3.875"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
